import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getMyServiceUnits = () => api({
  method: 'clinical.clinical.doctype.user_service_unit.user_service_unit.get_user_service_unit',
})

export const getDraftPatientEncounters = (patient, source = '', service_unit = {}) => api({
  method: 'clinical.api.patient_encounter.patient_encounter.get_draft_patient_encounter',
  args: {
    patient,
    source,
    service_unit,
  },
})

export const getDraftMedicationOrder = patient => api({
  method: 'clinical.api.inpatient_medication_orders.inpatient_medication_orders.get_draft_medication_orders',
  args: {
    patient,
  },
})
