<template>
  <b-container class="w-100 top-header-container" />
</template>

<script>

import Encounter from './components/encounter/Encounter.vue'
import SideBar from '../sidebar/Sidebar.vue'
import RequestForAmbulance from './components/request-for-ambulance/RequestForAmbulance.vue'
import LateMealRequest from './components/late-meal-request/LateMealRequest.vue'
import TraverseQueue from './components/traverse-queue/TraverseQueue.vue'
import QuickRegisterPatient from './components/quick-register/QuickRegisterPatient.vue'
import ServicePointSelect from './components/service-point-select/ServicePointSelect.vue'
import VacateServicePoint from './components/vacate-service-point/VacateServicePoint.vue'

export default {
  name: 'TopHeader',
  components: {
    Encounter, RequestForAmbulance, SideBar, LateMealRequest, ServicePointSelect, VacateServicePoint, TraverseQueue, QuickRegisterPatient,
  },
  data() {
    return {
      socket: null,
    }
  },
  computed: {
    notificationList() {
      return this.$store.getters['notification/getNotificationList']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit'] || (this.serviceUnits.length && this.serviceUnits[0])
    },
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint'] || (this.servicePoints.length && this.servicePoints[0])
    },
  },
  mounted() {
    const { user } = this.getUserSession()
    this.getNotifications(user)
  },

  methods: {
    search(search) {
      this.getPatientList(this.selectedServiceUnit.service_unit, search)
    },
    getPatientList(serviceUnit, search) {
      if (serviceUnit) {
        const payload = { serviceUnit, search, work: 'patient/component/main2' }
        this.$store.dispatch('patient/fetchPatients', payload)
      }
    },
    checkIfMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.mobile = true
      }
    },
    getNotifications(user) {
      const payload = { ref: user }
      this.$store.dispatch('notification/fetchNotifications', payload)
    },

    clearNotification(notification) {
      const { name } = notification
      const payload = { name, user: this.getUserSession().user }
      this.$store.dispatch('notification/clearNotification', payload)
      this.setCurrentDoctype(
        notification.reference_doctype,
        notification.doctype_reference,
      )
    },

    setCurrentDoctype(doctype, reference) {
      this.selectedDoctype = doctype
      this.selectedReference = reference
      if (reference && doctype) {
        this.$router.push({ name: 'viewer', params: { doctype, reference } })
      }

      // this.$refs["my-modal-1993"].show();
    },
  },
}
</script>
<style scoped>
.card {
  margin-bottom: 0px !important;
}
</style>
