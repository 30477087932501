<template>
  <b-container>
    <b-row>
      <b-col>
        <b-button
          v-if="isCompressed"
          variant="danger"
          size="sm"
          @click="openModal()"
        ><i class="fa fa-ambulance" /> Ambulance</b-button>

        <b-button
          v-else
          style="width: 100%"
          variant="danger"
          size="sm"
          @click="openModal()"
        >Request Ambulance</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { requestAmbulance } from './service'

export default {
  name: 'RequestForAmbulance',
  props: {
    isCompressed: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      ambulance_patient_name: 'ydehdec',
    }
  },
  computed: {
    patientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },

  watch: {
    selectedPatient(activePatient) {
      this.getPatientDetails(activePatient.patient)
    },
  },

  mounted() {
    if (this.selectedPatient && this.selectedPatient.patient) {
      this.getPatientDetails(this.selectedPatient.patient)
    }
  },
  methods: {
    getPatientDetails(patient_name) {
      const payload = { patient_name }
      this.$store.dispatch('patient/fetchSelectedPatientDetails', payload)
    },
    openModal1() {
      const parent = this
      const dlg = new frappe.ui.Dialog({
        title: 'Request Ambulance',
        fields: [
          {
            label: 'Requestor',
            fieldname: 'requestor',
            reqd: 1,
            fieldtype: 'Link',
            options: 'Employee',
          },
          // {
          //   label: "From",
          //   fieldname: "from",
          //   fieldtype: "Data",
          // },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          // {
          //   label: "Destination",
          //   fieldname: "destination",
          //   fieldtype: "Data",
          // },
          {
            label: 'Date Time',
            fieldname: 'date_and_time_of_departure',
            fieldtype: 'Datetime',
            reqd: 1,
          },
          {
            fieldname: 'section_break_2',
            fieldtype: 'Section Break',
          },
          // {
          //   label: "Purpose of visit",
          //   fieldname: "purpose_of_visit",
          //   fieldtype: "Text",
          // },
          {
            label: 'Ambulance Occupancy Details( Enter list of patients to ride in the ambulance) ',
            fieldname: 'patients_ambulance_occupancy',
            fieldtype: 'Table',
            options: 'Patients Ambulance Occupancy',
            fields: [
              {
                fieldtype: 'Link',
                options: 'Patient',
                fieldname: 'patient',
                in_list_view: 1,
                label: 'Patient',
                reqd: 1,
                onchange(e) {
                  this.api({
                    method:
                      'fleet.api.fleet_request.get_ambulance_patient_name',
                    args: {
                      name: '',
                    },
                    callback: r => {
                      const data = r.message
                      console.log('ty', data)
                      parent.changeListener(dlg, parent)
                    },
                  })
                },
              },
              {
                label: 'Name',
                fieldname: 'patient_name',
                fieldtype: 'Read Only',
                in_list_view: 1,
                default: 'bbgfbvf',
                reqd: 1,
              },

              {
                fieldtype: 'Link',
                options: 'Healthcare Service Unit',
                fieldname: 'origin',
                in_list_view: 1,
                label: 'Origin',
                reqd: 1,
              },
              {
                fieldtype: 'Link',
                options: 'Healthcare Service Unit',
                fieldname: 'destination',
                in_list_view: 1,
                label: 'Destination',
                reqd: 1,
              },
              {
                fieldtype: 'Text',
                fieldname: 'purpose',
                in_list_view: 1,
                label: 'Purpose',
                reqd: 1,
              },

            ],
          },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          const data = { ...values, patients_ambulance_occupancy: values.patients_ambulance_occupancy }
          requestAmbulance(data).then(() => {
            dlg.hide()
            this.show_alert(
              {
                message: ('Ambulance Successfully Requested'),
                indicator: 'green',
              },
              5,
            )
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dlg.hide()
        },
      }).show()
      dlg.$wrapper.find('.modal-content').css('width', '1000px')
      dlg.$wrapper.find('.modal-content').css('margin-left', '-200px')
      dlg.$wrapper.find('.modal-content').css('backdrop', 'static')
      dlg.$wrapper.find('.modal-content').css('keyboard', 'false')
    },
    openModal() {
      let dialog
      const parent = this
      // let warehouses = []

      const fields = [

        {
          fieldtype: 'Link',
          options: 'Patient',
          fieldname: 'patient',
          in_list_view: 1,
          label: 'Patient',
          reqd: 1,
          onchange(e) {
            parent.changeListener(dialog, parent)
          },
        },
        {
          label: 'Name',
          fieldname: 'patient_name',
          fieldtype: 'Read Only',
          in_list_view: 1,
        },

        {
          fieldtype: 'Link',
          options: 'Healthcare Service Unit',
          fieldname: 'origin',
          in_list_view: 1,
          label: 'Origin',
          reqd: 1,
        },
        {
          fieldtype: 'Link',
          options: 'Healthcare Service Unit',
          fieldname: 'destination',
          in_list_view: 1,
          label: 'Destination',
          reqd: 1,
        },
      ]
      dialog = new frappe.ui.Dialog({
        title: 'Request For Ambulance',
        fields: [
          {
            label: 'Requestor',
            fieldname: 'requestor',
            reqd: 1,
            fieldtype: 'Link',
            options: 'Employee',
          },
          {
            fieldname: 'column_break',
            fieldtype: 'Column Break',
          },
          {
            label: 'Date Time',
            fieldname: 'date_and_time_of_departure',
            fieldtype: 'Datetime',
            reqd: 1,
          },
          {
            fieldname: 'section_break_2',
            fieldtype: 'Section Break',
          },
          {
            fieldname: 'item_list',
            fieldtype: 'Table',
            label: 'Items',
            cannot_add_rows: false,
            in_place_edit: true,
            reqd: 1,
            data: [],
            fields,
          },
        ],
        primary_action_label: 'Submit',
        primary_action: values => {
          console.log('think', values)
          const data = { ...values, patients_ambulance_occupancy: values.item_list }
          requestAmbulance(data).then(() => {
            dialog.hide()
            this.show_alert(
              {
                message: ('Ambulance Successfully Requested'),
                indicator: 'green',
              },
              5,
            )
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dialog.hide()
        },
      })

      dialog.show()
      dialog.$wrapper.find('.modal-content').css('width', '1000px')
      dialog.$wrapper.find('.modal-content').css('margin-left', '-200px')
      dialog.$wrapper.find('.modal-content').css('backdrop', 'static')
      dialog.$wrapper.find('.modal-content').css('keyboard', 'false')

      $('.modal-dialog').modal({
        backdrop: 'static',
        keyboard: false,
      })
    },
    changeListener(dialog, parent) {
      const data = dialog.fields[4].data || []

      const total = 0
      const promises = data.map(value => {
        const { patient_name } = value

        this.api({
          method:
                      'fleet.api.fleet_request.get_ambulance_patient_name',
          args: {
            name: value.patient || '',
          },
          callback: r => {
            const data = r.message
            value.patient_name = data
          },
        })
      })
      dialog.refresh()
    },
  },
}
</script>

<style scoped></style>
