<template>
  <div>
    <b-table class="w-100" :fields="fields" stacked="md" small bordered hover :items="medicationOrders">
      <template #cell(action)="row">
        <b-row >
          <b-col>
            <b-button block class="mb-1" size="sm" :disabled="row.item.is_completed === 1 || getStatus(row.item) === 'Pending'"
              :variant="row.item.is_completed ? 'success' : getStatus(row.item) === 'Overdue' ? 'danger' : 'primary'"
              @click="info(row.item, $event.target)">
              &#x2713;
              <!-- {{ row.item.is_completed ? 'Completed' : getStatus(row.item) === 'Pending' ? 'Pending' : 'Administer' }} -->
            </b-button>
          </b-col>

          <b-col v-if="!row.item.is_completed">
            <b-button block size="sm" v-if="!row.item.is_completed" variant="primary" @click="confirmAdministration(row.item)">
              Skip
            </b-button>
          </b-col>


          <b-col v-if="row.item.is_completed"> <b-button block size="sm" v-if="row.item.is_completed" variant="primary"
              @click="undoAdministration(row.item)">
              Undo
            </b-button></b-col>
        </b-row>
      </template>
    </b-table>
    <b-modal :id="infoModal.id" :ok-disabled="!validPf" size="lg" :title="infoModal.title" @ok="submit(infoModal.content)"
      @hide="resetInfoModal">
      <template #modal-title>
        <h3>{{ infoModal.title }}</h3>
      </template>
      <pre>{{ infoModal.content.drug_name }}</pre>
      <strong>Scheduled Time</strong>
      <pre>{{ infoModal.content.date }} at {{ infoModal.content.time }}</pre>
      <div style="margin-top: 16px">
        <strong>NDC Drug Name:</strong>
        <pre style="margin-bottom: 0">{{ infoModal.content.ndc_drug_name }}</pre>
      </div>
      <div style="margin-top: 16px">
        <strong>Dose:</strong>
        <pre style="margin-bottom: 0">{{ infoModal.content.dose }}</pre>
      </div>
      <div style="margin-top: 16px">
        <strong>Route of Administration:</strong>
        <pre style="margin-bottom: 0">{{ infoModal.content.route_of_administration }}</pre>
      </div>
      <div style="margin-top: 16px">
        <strong>Instructions:</strong>
        <pre style="margin-bottom: 0">{{ infoModal.content.instructions }}</pre>
      </div>
      <label for="pf-number">Enter your PF number to confirm Medication Entry Administration</label>
      <b-form-input id="pf-number" v-model="text" :state="validPf" class="error" placeholder="Enter your PF Number"
        @blur="blur()" @focus="focus()" />
      <template #modal-ok>
        Confirm
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {
  getPatientMedicationOrders,
  createMedicationEntry,
  validatePFNumber,
  ignoreDrugAdministration,
  undoDrugAdministration,
} from './service'

export default {
  name: 'InpatientDrugAdministration',
  props: {
    patientName: {
      type: String,
      default: '',
      required: true,
    },
    serviceUnit: {
      type: String,
      default: '',
      required: true,
    },
    tabIndex: {
      type: Number,
      default: '',
      required: true,
    },
    index: {
      type: Number,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      medicationOrders: [],
      fields: [
        'drug_name',
        'instructions',
        'dosage',
        'is_discharge_medication',
        {
          label: 'Scheduled Time',
          key: 'date',
          formatter: (value, key, item) => moment(`${item.date} ${item.time}`).format('MMMM Do YYYY, h:mm:ss a'),
        },
        'is_matched', 'is_one_off',
        // {
        //   label: 'Administration Time',
        //   key: 'administration_time',
        //   formatter: (value, key, item) => {
        //     return value ? moment(value).fromNow() : '-';
        //   },
        // },
        { label: '', key: 'action' },
      ],
      text: '',
      validPf: false,
      infoModal: {
        id: 'info-modal',
        title: '',
        data: {},
        content: {},
      },
    }
  },
  computed: {},
  watch: {
    patientName() {
      getPatientMedicationOrders(this.patientName).then(medicationOrders => {
        this.medicationOrders = medicationOrders
      })
    },
    tabIndex() {
      if (this.tabIndex === this.index) {
        getPatientMedicationOrders(this.patientName).then(medicationOrders => {
          this.medicationOrders = medicationOrders
        })
      }
    },
  },
  mounted() {
    getPatientMedicationOrders(this.patientName).then(medicationOrders => {
      this.medicationOrders = medicationOrders
      console.error(medicationOrders, medicationOrders.length)
    })
    console.log('INPATIENT DRUG ADMINISTRATION LOADED')
  },
  methods: {
    undoAdministration(item) {
      frappe.confirm('Are you sure you want to undo drug administration?. This action will unbill the patient',
        () => {
          undoDrugAdministration({ entry_name: item.name })
            .then(() => {
              this.medicationOrders = this.medicationOrders.map(select => (item.name === select.name ? { ...select, is_completed: 0 } : select))
            })
        }, () => {

        })
    },
    info(item, button) {
      this.infoModal.title = `Confirm Order to ${this.patientName}`
      this.infoModal.content = item
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    getStatus(item) {
      const value = item.is_completed
      const time = +new Date(`${item.date} ${item.time}`)
      if (time < +new Date() && !value) {
        return 'Overdue'
      }
      return value ? 'Completed' : 'Pending'
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = {}
    },
    confirmAdministration(item) {
      frappe.prompt({
        label: 'Reason for skipping this medication',
        fieldname: 'reason',
        fieldtype: 'Text',
      }, values => {
        ignoreDrugAdministration({ name: item.name, reason: values.reason })
          .then(() => {
            this.medicationOrders = this.medicationOrders.map(select => (item.name === select.name ? { ...select, is_completed: 1 } : select))
          })
      })
    },
    submit(order) {
      createMedicationEntry({ order, ward: this.serviceUnit }).then(response => {
        const current = this
        current.medicationOrders = current.medicationOrders.map(e => ({ ...e, is_completed: e.name === order.name ? 1 : e.is_completed }))
        // getPatientMedicationOrders(this.patientName).then((medicationOrders) => {
        //   current.medicationOrders = medicationOrders;
        // });
        // createSalesOrder({
        //   patient: order.patient,
        //   patient_encounter: '',
        //   inpatient_record: order['inpatient_record'],
        //   document_id: order['inpatient_record'],
        //   patient_visit: 'Inpatient Record',
        //   reference_item: 'Inpatient Medication Entry',
        //   item_record: response.name,
        //   item: {
        //     item_code: order.drug,
        //     qty: order.dosage,
        //   },
        //   customer: '',
        //   service_unit: this.serviceUnit,
        //   patient_appointment: '',
        // }).then(console.log);
      })
    },
    blur() {
      validatePFNumber(this.text).then(value => {
        this.validPf = value
      })
    },
    focus() {
      validatePFNumber(this.text).then(value => {
        this.validPf = false
      })
    },
  },
}
</script>
