import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const patientLateMealRequest = payload => api({
  method: 'clinical.api.late_meal_request.actions.create_late_meal_request',
  args: { payload },
})
