<template>
  <div v-if="tabList.length > 0 || true">

    <div>
      <b-tabs v-model="tabIndex" small :end="mobile">
        <b-tab v-for="(tab, index) of tabList " :key="tab" :title="tab" :active="index === tabIndex" title-item-class="milele">
          <template #title>
            
           <div  class="equate"  > <span class="icon-header" :style="{ color: tabProp[tab] == null ? 'lightblue' : tabProp[tab].color }">
              <!-- <b-spinner :variant="variants[index]" type="grow" small></b-spinner> -->
              <i v-if="mobile" class="fa fa-2xl" :class="tabProp[tab] == null ? 'fa-radiation' : tabProp[tab].icon"
                aria-hidden="true" style="font-size: large;" />

              <i v-else class="fa" :class="tabProp[tab] == null ? 'fa-radiation' : tabProp[tab].icon"
                aria-hidden="true" />
              <span v-if="!mobile || (mobile && index === tabIndex && false) " style="font-size: 10px;"> {{ tab | truncate(10) }}</span>
            </span></div>
          </template>
          <b-card-text class="px-1" :class="[fullScreenWorkArea ? expanded : '', shrinked]">
            <inpatient-drug-administration v-if="tab === 'Labs'" :action="lastAction" :tab-index="tabIndex"
              @tab-actions="emitActions" @tab-buttons="emitButtons" />
            <inpatient-drug-administration v-else-if="tab === 'Drug Administration'" :action="lastAction"
              :tab-index="tabIndex" :index="index" :patient-name="selectedPatient.patient" :service-unit="serviceUnit"
              @tab-actions="emitActions" @tab-buttons="emitButtons" />

            <component :is="timelineType" v-else-if="tab === 'Timeline'" :action="lastAction" :tab-index="tabIndex"
              :patient="selectedPatient" :current-scroll-position="topOffSet" :max-scroll-position="maxtopOffSet"
              :is-active="tabList[tabIndex] === 'Timeline'" @tab-actions="emitActions" @tab-buttons="emitButtons" />

            <fill-form v-else-if="tab === 'Kardex'" class="border-side " :form-name="'Kardex Notes'"
              :referenc-doctype="'Patient'" :referenc-doctype-item="selectedPatient.patient"
              :patient_number="selectedPatient.patient" :action="lastAction" :tab-index="tabIndex"
              @tab-actions="emitActions" @tab-buttons="emitButtons" />

            <fill-form v-else-if="tab === 'Vital Signs'" class="border-side " :form-name="'Vital Signs'"
              :patient_number="selectedPatient.patient" :referenc-doctype="'Patient'"
              :referenc-doctype-item="selectedPatient.patient" :action="lastAction" :tab-index="tabIndex"
              @tab-actions="emitActions" @tab-buttons="emitButtons" @encounterCallback="encounterCallback" />

            <fill-form v-else-if="tab === 'Doctors Notes'" class="border-side " :form-name="'Doctors notes'"
              :patient_number="selectedPatient.patient" :referenc-doctype="'Patient'"
              :referenc-doctype-item="selectedPatient.patient" :encounter="encounter" :action="lastAction"
              :tab-index="tabIndex" @encounterUpdated="encounterUpdated" @tab-actions="emitActions"
              @tab-buttons="emitButtons" @encounterCallback="encounterCallback" />
            <procedure-administration v-else-if="tab === 'Procedures'" :is-active="tabList[tabIndex] === 'Procedures'"
              :patient="selectedPatient" />
            <orders v-else-if="tab === 'Orders'" :service-unit="serviceUnit" :selected-patient="selectedPatient"
              :medication-order="medicationOrder" :encounter="encounter" :patient="selectedPatient.patient"
              @medicationOrderUpdated="medicationOrderUpdated" @encounterUpdated="encounterUpdated" />

            <patient-allergy v-else-if="tab === 'Allergies'" :patient="selectedPatient.patient" />
            <forms-tab v-else-if="tab === 'Forms'" :patient="selectedPatient" :action="lastAction" :tab-index="tabIndex"
              @tab-actions="emitActions" @tab-buttons="emitButtons" />

            <discharge v-else-if="tab === 'Discharge'" :encounter="encounter" @encounterUpdated="encounterUpdated" />

            <div v-else :tabIndex="tabIndex">
              Tab Content
            </div>
            <b-button v-if="topOffSet > 100" class="scroll-to-top" @click="scrollToTop()">
              <i class="fa fa-arrow-circle-up" /></b-button>
          </b-card-text></b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import FillForm from '@/views/components/formbuilder/FillForm.vue'
import Timeline from './components/timeline/Timeline.vue'
import TimelineSm from './components/timeline/TimelineSm.vue'
import {
  getMyPatientTabPermissions,
  getDraftPatientEncounters,
  createPatientEncounter,
} from './services'

import InpatientDrugAdministration from './components/inpatient-drug-administration/InpatientDrugAdministration.vue'
import Orders from './components/orders/Orders.vue'
import ScrollTopArrow from './components/scroll/ScrollTopArrow.vue'
import FormsTab from './components/forms/FormsTab.vue'
import Discharge from './components/discharge/Discharge.vue'
import PatientAllergy from './components/patient-allergy/PatientAllergy.vue'

export default {
  name: 'PatientChart',
  components: {
    InpatientDrugAdministration,
    Timeline,
    TimelineSm,
    Orders,
    ScrollTopArrow,
    FillForm,
    FormsTab,
    Discharge,
    PatientAllergy,
  },
  props: {
    selectedPatient: Object,
    serviceUnit: Object,
    lastAction: String,
    encounter: Object,
    medicationOrder: Object,
    fullScreenWorkArea: Object,
  },
  data() {
    return {
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        {
          age: 89,
          first_name: 'Geneva',
          last_name: 'Wilson',
          _rowVariant: 'danger',
        },
        {
          age: 40,
          first_name: 'Thor',
          last_name: 'MacDonald',
          _cellVariants: { age: 'info', first_name: 'warning' },
        },
        { age: 29, first_name: 'Dick', last_name: 'Dunlap' },
      ],
      tabIndex: 0,
      tabList: [],
      allTabs: [],
      expanded: 'tab-content-height-expanded',
      shrinked: 'tab-content-height',
      timelineType: 'Timeline',
      tabProp: {
        Timeline: {
          icon: 'fa-line-chart',
          color: 'green',
        },
        Procedures: {
          icon: 'fa-scissors',
          color: '#a4a433',
        },
        'Drug Administration': {
          icon: 'fa-medkit',
          color: 'royalblue',
        },
        'Vital Signs': {
          icon: 'fa-heartbeat',
          color: 'rgb(147, 4, 4)',
        },
        Kardex: {
          icon: 'fa-pen-to-square',
          color: '#0e9595',
        },
        'Doctors Notes': {
          icon: 'fa-user-md',
          color: '#a4a433',
        },
        Orders: {
          icon: 'fa-layer-group',
          color: '#28a745',
        },
        Prescriptions: {
          icon: 'fa-flask',
          color: 'royalblue',
        },
        Accounts: {
          icon: 'fa-credit-card-alt',
          color: 'darkorange',
        },
        Forms: {
          icon: 'fa-solid fa-notes-medical',
          color: 'rgb(52, 113, 166)',
        },
        Labs: {
          icon: 'fa-flask',
          color: 'darkblue',
        },
        Discharge: {
          icon: 'fa-list-alt',
          color: '#a4a433',
        },
        Allergies: {
          icon: 'fa-solid fa-hand-dots',
          color: 'darkorange',
        },
      },

      variants: [
        'primary',
        'secondary',
        'danger',
        'warning',
        'success',
        'info',
        'dark',
        'primary',
        'secondary',
        'danger',
        'warning',
        'success',
        'info',
        'light',
        'dark',
      ],
      topOffSet: 0,
      maxtopOffSet: 0,
      currentElement: null,
      mobile: false,
      ignore_list: [],
    }
  },
  watch: {
    tabIndex(index) {

      if (this.tabList.length) {
        this.setTitle(this.tabList[index])
      }
      this.scrollToTop()
    },
    selectedPatient() {
      if (!this.selectedPatient.is_inpatient) {
        this.tabList = this.allTabs.sort(sortFunc).filter(n => !ignore_list.includes(n))
        if (this.mobile) {
          this.ignore_list = ["Vital Signs", "Kardex", "Doctors Notes"]
          this.tabList = this.tabList.filter(n => {
            return !this.ignore_list.includes(n)
          })


        }
      }
    },
  },
  created() {
    this.checkIfMobile()
    if (this.mobile) {
      this.timelineType = 'TimelineSm'
    }
    window.addEventListener('scroll', this.scrollListener)
  },
  mounted() {
    const context = this

    getMyPatientTabPermissions().then(tabList => {
      let tabsOrder = [
        'Timeline',
        'Procedures',
        'Drug Administration',
        'Vital Signs',
        'Kardex',
        'Doctors Notes',
        'Allergies',
        'Orders',
        'Prescriptions',
        'Accounts',
        'Forms',
        'Labs',
        'Discharge',

      ]

      if (this.mobile) {
        tabsOrder = [
          'Timeline',
          'Procedures',
          'Drug Administration',
          'Vital Signs',
          'Kardex',
          'Doctors Notes',
          'Forms',
          'Allergies',
          'Orders',
          'Prescriptions',
          'Accounts',
          'Labs',
          'Discharge',

        ]
      }


      const sortFunc = (a, b) => {
        if (tabsOrder.indexOf(a) > tabsOrder.indexOf(b)) {
          return 1
        }
        return -1
      }
      const notes = ["Vital Signs", "Kardex", "Doctors Notes"];
      this.allTabs = tabList.sort(sortFunc)
      if (this.mobile) {
        this.allTabs = this.allTabs.filter(item => !notes.includes(item));
      }
      this.tabList = tabList.sort(sortFunc).filter(item => !notes.includes(item));
      if (!this.selectedPatient.is_inpatient) {
        const tabs = this.allTabs
        this.tabList = tabs.filter(n => n !== 'Drug Administration')
      }



    })
    getDraftPatientEncounters(this.selectedPatient.patient).then(
      encounter => {
        this.$emit('encounterUpdated', encounter)
      },

    )
    setTimeout(() => {
      const tabContents = document.getElementsByClassName('tab-content-height')
      tabContents.forEach(element => {
        element.addEventListener('scroll', e => {
          context.scrollListener(element)
        })
      })
      // alert(tabContents)
    }, 1000)
  },

  methods: {
    encounterCallback(patient) {
      createPatientEncounter(patient).then(encounter => this.updateEncounter(encounter))
    },
    updateEncounter(encounter) {
      this.$store.dispatch('encounter/setEncounter', encounter)
      // this.reloadClock();
    },
    scrollToTop() {
      this.currentElement.scrollTop = 0
      this.topOffSet = 0
    },
    checkIfMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        this.mobile = true
      }
    },
    scrollListener(element) {
      this.currentElement = element

      let maxScroll = element.scrollTopMax
      if (typeof maxScroll === 'undefined') {
        maxScroll = element.scrollHeight - element.clientHeight
      }
      this.topOffSet = element.scrollTop
      this.maxtopOffSet = maxScroll
    },
    emitActions(actions) {
      this.$emit('tab-actions', actions)
    },
    encounterUpdated(encounter) {
      this.$emit('encounterUpdated', encounter)
    },
    emitButtons(buttons) {
      this.$emit('tab-buttons', buttons)
    },
    medicationOrderUpdated(order) {
      this.$emit('medicationOrderUpdated', order)
    },
  },
}
</script>

<style scoped>
svg {
  max-width: 10px !important;
}

.card {
  border: 1px solid lightgray !important;
  padding: 0px !important;
}

.tab-content-height {
  min-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 66vh !important;
}

.tab-content-height-expanded {
  min-height: 200px;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: hidden;
  height: 66vh !important;
}

.spinner-grow-sm {
  width: 7px;
  height: 7px;
  margin-bottom: 3px;
}

.spinner-grow {
  display: inline-block;
  width: 7px;
  height: 7px;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 1;
  -webkit-animation: spinner-grow 0.75s linear 1;
  animation: spinner-grow 0.75s linear 1;
}

.scroll-to-top {
  position: absolute;
  right: 19px;
  bottom: 11vh;
  z-index: 1000;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.1);
  font-size: large;
}

#button {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 1;
  visibility: hidden;
  z-index: 1000;
}

.primary {
  color: #ff9800;
}

.border-side {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 8px;
}

/* .tabs-area{
   overflow-y: scroll;
  overflow-x:hidden;
  height: calc(100vh - 200px);
} */

@media only screen and (max-width: 767px) {
   .nav-item {
    width: 20% !important;
  }

  svg {
      max-width: none !important;
      /* width: 100% !important;
      height: 20px !important;
      margin-bottom: 15.8px;
      padding-top: 15.8px; */
      
  }
  .tabs {
    
     padding-left: 0px;
  }
  .nav-tabs {
    padding: 19px !important;
  }

 

  
}
</style>
