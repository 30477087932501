<template>
  <b-container>
    <b-row>
      <b-col>
        <b-button
          v-if="isCompressed"
          variant="success"
          size="sm"
          @click="openModal()"
        ><i class="fa fa-cutlery" /> Meal</b-button>

        <b-button
          v-else
          style="width: 100%"
          variant="danger"
          size="sm"
          @click="openModal()"
        >Request Late Meal</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { patientLateMealRequest } from './service'

export default {
  name: 'RequestForAmbulance',
  props: {
    isCompressed: { type: Boolean, required: false, default: false },
  },
  computed: {
    patientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
  },

  watch: {
    selectedPatient(activePatient) {
      this.getPatientDetails(activePatient.patient)
    },
  },

  mounted() {
    if (this.selectedPatient && this.selectedPatient.patient) {
      this.getPatientDetails(this.selectedPatient.patient)
    }
  },
  methods: {
    getPatientDetails(patient_name) {
      const payload = { patient_name }
      this.$store.dispatch('patient/fetchSelectedPatientDetails', payload)
    },
    openModal() {
      const parent = this
      const dlg = new frappe.ui.Dialog({
        title: 'Late Meal Request',
        fields: [
          {
            label: 'Date',
            fieldname: 'requested_on',
            reqd: 1,
            fieldtype: 'Date',
          },
          {
            label: 'Time',
            fieldname: 'time_requested',
            reqd: 1,
            fieldtype: 'Time',
          },
          {
            label: 'Reason',
            fieldname: 'reason_for_request',
            reqd: 1,
            fieldtype: 'Long Text',
          },

        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          const request = {
            ward: parent.selectedServiceUnit.service_unit,
            patient: parent.selectedPatient.patient,
            patient_name: parent.selectedPatient.patient_name,
            requested_by: this.getUserSession().user_email,
          }
          // const purpose = `Ambulance request to be accompanied by `;
          const payload = { ...values, ...request }

          // alert(JSON.stringify(payload))

          patientLateMealRequest(payload).then(() => {
            dlg.hide()
            this.show_alert('successfully saved')
          })
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dlg.hide()
        },
      }).show()
    },
  },
}
</script>

<style scoped></style>
