<template>
  <div class="border-side">
    <b-container>
      <b-row align-h="between">
      
        <h5>     </h5>

        <b-row>
          <b-button
            v-if="patientAllergy"
            size="sm"
            variant="primary"
            class="mr-1 mb-1"
            @click="showAddChargeDialog()"
          >
            Add
          </b-button>

          <b-button
            v-if="patientAllergy"
            class=" mb-1"
            variant="danger"
            size="sm"
            @click="deleteSheet()"
          >
            Delete
          </b-button>
          <div
            v-if="patientAllergy || this.values.length"
            class="ml-0"
          />

          <b-button
            v-else
            size="sm"
            variant="primary"
            class="mr-2 mb-1"
            @click="callCreateAllergyApi()"
          >
            Create
          </b-button>
        </b-row>

      </b-row>

      <b-row style="width: 100%">
        <b-table
          style="width: 100%"
          responsive="sm"
          bordered
          stacked="sm"
          small
          striped
          :fields="fields"
          :items="values"
          :empty-text="`No items `"
          :empty-filtered-text="`No items`"
          head-row-variant="secondary"
          :show-empty="true"
        >
          <template #cell(actions)="data">
            <b-button
              size="sm"
              variant="danger"
              @click="removeItem(data)"
            >
              Remove
            </b-button>
          </template>

          <template #cell(status)="data">
            <b-icon
              v-if="data.item && data.item.id"
              disabled
              icon="check-square"
              scale="2"
              variant="success"
            />
            <b-button
              v-else
              variant="primary"
            >
              <b-spinner
                small
                type="grow"
              />
              Saving...
            </b-button>
          </template>
        </b-table>
      </b-row>
    </b-container>
    <b-modal
      v-model="showModal"
      title="Add Allergy"
      button-size="sm"
      @ok="handleAddItem"
    >
      <b-form-group
        label="Allergy"
        label-for="allergy_name"
      >
        <b-form-input
          id="allergy_name"
          v-model="formData.allergy_name"
          required
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  createPatientAllergy,
  patientAllergySubmit,
  patientAllergyAddItem,
  patientAllergyRemoveItem,
  checkPatientAllergy,
  patientAllergyDelete,
} from './services'

export default {
  name: 'PatientAllergy',
  props: {
    patient: {
      default: '',
      type: String,
      required: true,
    },
  },

  data() {
    return {
      values: [],
      fields: ['allergy_name', 'status', 'actions'],
      patientAllergy: null,
      showModal: false,
      formData: {
        allergy_name: '',
      },
    }
  },
  watch: {
    currentSelectedPatient(){
    this.checkIfPatientAllergyExits()
  }
  },
  mounted() {
    this.checkIfPatientAllergyExits();
  },
  methods: {
    handleAddItem() {
      const available = this.values.filter(item => item.allergy_name === this.formData.allergy_name)

      if (available.length) {
        this.makeToast('danger', 'Item already in the list', 'Error')
      } else {
        this.values.push({ allergy_name: this.formData.allergy_name })
        this.addItem({ allergy_name: this.formData.allergy_name, patient_allergy: this.patientAllergy })
        this.$nextTick(() => {
          this.showModal = false
        })
      }
    },
    cancelAddItem() {
      this.showModal = false
    },
    checkIfPatientAllergyExits() {
      checkPatientAllergy(this.patient).then(response => {
        if (response) {
          this.patientAllergy = response.name

          response.items.forEach(item => {
            this.values.push({ allergy_name: item.allergy_name, status: 'Saved', id: item.name })
          })
        }
      })
    },
    submitPatientAllergy() {
      patientAllergySubmit({ name: this.patientAllergy }).then(response => {
        this.values = []
      })
    },
    removeItem(data) {
      data = data.item
      if (data) {
        patientAllergyRemoveItem(data.id).then(response => {
          this.values = this.values.filter(
            item => item.id != data.id,
          )
        })
      }
    },
    showAddChargeDialog() {
      this.showModal = true
    },
    showAddChargeDialog1() {
      const context = this
      const x = new frappe.ui.Dialog({
        title: 'Add Allergy',
        fields: [
          {
            label: 'Allergy',
            fieldname: 'allergy_name',
            fieldtype: 'Data',
            reqd: true,
          },
        ],
        primary_action_label: 'Add Item',
        primary_action: values => {
          const available = this.values.filter(item => item.allergy_name == values.allergy_name)
          if (available.length) {
            this.makeToast(variant = 'danger', message = 'Item already in the list', title = 'Error')
          } else {
            context.values.push(values)
            const singleAllergy = values

            this.addItem({ allergy_name: singleAllergy.allergy_name, patient_allergy: context.patientAllergy })

            x.hide()
            this.showAddChargeDialog()
          }
        },
        secondary_action_label: 'Cancel',
        secondary_action: values => {
          x.hide()
        },
      })
      x.show()
    },
    callCreateAllergyApi() {
      const patientAllergy = {
        patient: this.patient,
        doctype: 'Patient Allergy',
      }
      createPatientAllergy(patientAllergy).then(response => {
        this.patientAllergy = response
        alert(JSON.stringify(this.patientAllergy))
        this.makeToast()
        this.values = []
      })
    },
    makeToast(variant = 'success') {
      this.$bvToast.toast('Allergy Saved', {
        title: 'Success',
        variant,
        solid: true,
      })
    },
    createAllergy() {
      this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Are you sure?')
        .then(value => {
          if (value) {
            this.callCreateAllergyApi()
          }
        })
        .catch(err => {
          // An error occurred
        })
    },

    submitSheet() {
      this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Are you sure?')
        .then(value => {
          if (value) {
            patientAllergySubmit(this.patientAllergy).then(response => {
              this.patientAllergy = null
              this.values = []
            })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },

    deleteSheet() {
      this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Are you sure?')
        .then(value => {
          if (value) {
            patientAllergyDelete(this.patientAllergy).then(response => {
              this.patientAllergy = null
              this.values = []
            })
          }
        })
        .catch(err => {
          // An error occurred
        })
    },

    addItem(payload) {
      patientAllergyAddItem(payload).then(res => {
        this.makeToast('success', 'Allergy added')
        const items = []
        this.values.forEach((element, index) => {
          if (payload.allergy_name == element.allergy_name) {
            items.push({
              allergy_name: element.allergy_name,
              status: 'Saved',
              id: res,
            })
          } else {
            items.push(element)
          }
        })

        this.values = items
      })
    },
  },

}
</script>

<style>
</style>
