<template>
  <div class="patient-info  ">
    <div style="width:100%" class="bgstyle">
      <b-row class="py-1" align-v="end">

        <b-col>
          <b-row style="width:100%">
            <b-col cols="12">
              <span>
                <b> {{ patient.patient_name }} [{{ patient.patient }} ] </b>
              </span>
            </b-col>

            <b-row align-h="start" class="w-100">
              <b-row class="w-100" align-h="between">
                <span>
                  <b> </b>
                </span>

              </b-row>
            </b-row>

            <b-col cols="12">
              <span> {{ patient.service_unit }} </span>
            </b-col>

            <b-col cols="12">
              <span v-if="patient.is_emergency === 1" style="color: red">
                Emergency
              </span>

              <b-badge variant="success">
                {{ patient.status }}
              </b-badge>
            </b-col>


          </b-row>
        </b-col>

       <b-row>
        <b-button v-b-toggle.sidebar-backdrop size="sm" variant="outline-secondary" class="mt-2 mr-1">
          <i class="fa-solid fa-user-pen"></i>
        </b-button>
        <b-button  v-b-toggle.sidebar-1678 size="sm" variant="outline-secondary" class="mt-2 mr-1">
          <i class="fa-solid fa-users"></i>
        </b-button>
       </b-row>

      </b-row>

      <b-col class="remove-agent flex-between" cols="12">
        <b-sidebar id="sidebar-backdrop" title="Patient Actions" :backdrop-variant="variant" backdrop shadow>

          <b-row class="p-2">

            <b-col cols="12" class="mb-1">
              <b-dropdown text="Actions" split split-variant="outline-secondary" size="sm" class="w-100" align-h="end">
                <b-dropdown-item> <sign-off :patient="patient" :un-submitted-procedures="unSubmittedProcedures"
                    :medication-order="medicationOrder" @sign-off="onSignOff" /></b-dropdown-item>
                <b-dropdown-item v-if="patient.status === 'Admission Scheduled'">
                  <admit-patient v-if="patient.status === 'Admission Scheduled'" class="" :patient="patient"
                    variant="success btn-sm" @click="admitPatient" @updateList="updateList">
                    Admit
                  </admit-patient></b-dropdown-item>
                <b-dropdown-item v-if="!!patient.appointment_datetime && !encounter.name">
                  <schedule-for-admission v-if="!!patient.appointment_datetime && !encounter.name" :patient="patient"
                    :encounter="encounter" @click="scheduleAdmission">
                    Schedule Admission
                  </schedule-for-admission></b-dropdown-item>
                <b-dropdown-item v-if="!!patient.appointment_datetime"> <b-button v-if="!!patient.appointment_datetime"
                    block class="" variant="danger btn-sm" @click="confirmRemovalFromList">
                    End Session
                  </b-button></b-dropdown-item>
                <b-dropdown-item v-if="patient.status === 'Admitted'"> <transfer-patient
                    v-if="patient.status === 'Admitted'" :patient="patient"
                    :service_unit="selectedServiceUnit" /></b-dropdown-item>

                <b-dropdown-item v-if="patient.status === 'Pending Transfer Approval'">
                  <approve-inpatient-transfer v-if="patient.status === 'Pending Transfer Approval'" :patient="patient"
                    :service_unit="selectedServiceUnit" /></b-dropdown-item>
                <b-dropdown-item> <theatre-scheduling /></b-dropdown-item>
              </b-dropdown>
            </b-col>

            <b-col cols="12">
              <b-dropdown
                v-if="patient.status !== 'Admitted' && patient.status !== 'Pending Transfer Approval' && !hideActions"
                text="Queue" size="sm" split split-variant="outline-secondary" align-h="end" class="w-100">
                <b-dropdown-item-button class="w-100"> <end-queue-session class="w-100" :encounterr="encounterr"
                    :details="patient" /></b-dropdown-item-button>
                <b-dropdown-item-button><call-patient-in-queue :details="patient" /></b-dropdown-item-button>
                <b-dropdown-item-button> <set-referral-in :details="patient" /></b-dropdown-item-button>
                <b-dropdown-item>
                  <outpatient-queue-transfer :details="patient" :service_unit="selectedServiceUnit" /></b-dropdown-item>
              </b-dropdown>

            </b-col>

            <b-col cols="12" class="py-2">
              <b-button v-if="patient.status === 'Admission Scheduled'" style="width: 100%" variant="success btn-sm"
                @click="admitPatient">
                Admit
              </b-button>

            </b-col>

            <!-- <b-col cols="12" class="py-1">
              <request-for-ambulance style="width: 100%" :is-compressed="false" />
            </b-col> -->

            <b-col cols="12"><b-button v-if="patient.status === 'Admitted'" style="width: 100%" variant="primary btn-sm"
                @click="transferPatient">
                Transfer
              </b-button></b-col>

            <b-col cols="12">
              <b-button v-if="!!patient.appointment_datetime" style="width: 100%" variant="danger btn-sm"
                @click="confirmRemovalFromList">
                Remove From List
              </b-button></b-col>
          </b-row>
        </b-sidebar>
      </b-col>

      <b-col cols="12">
        <b-modal id="collapse-954" v-model="collapsed" class="mt-1 mb-4">
          <hr>
          <div>
            <patient-details />
          </div>
        </b-modal>
      </b-col>

    </div>
  </div>
</template>

<script>
import PopOverSidebar from '../../../sidebar/PopOverSidebar.vue'
import Encounter from '@/views/erp/clinical/top-header/components/encounter/Encounter.vue'
import RequestForAmbulance from '@/views/erp/clinical/top-header/components/request-for-ambulance/RequestForAmbulance.vue'
import {
  getPatientInfo,
  submitEncounter,
  submitMedicationOrder,
  api,
  validatePFNumber,
  scheduleAdmission as scheduleAdmissionApi,
  createPatientEncounter,
  closeAppointment,
  admitPatient,
  checkAdmissionEligibility,
  submitLabPrescriptions,
  createAppointment,
  scheduleDischarge,
  getAvailabilityData,
  dischargeOrTransferBilling,
  submitDoctorsNotes,
} from './service'

import PatientDetails from './components/patient-details/PatientDetails.vue'
import SignOff from './components/sign-off/SignOff.vue'
import NextAppointment from './components/next-appointment/NextAppointment.vue'
import CallPatientInQueue from './components/call-patient-in-queue/CallPatientInQueue.vue'
import EndQueueSession from './components/end-queue-session/EndQueueSession.vue'
import TransferPatient from './components/transfer-patient/TransferPatient.vue'
import ApproveInpatientTransfer from './components/approve-inpatient-transfer/ApproveInpatientTransfer.vue'
import OutpatientQueueTransfer from './components/outpatient-queue-transfer/OutpatientQueueTransfer.vue'
import TheatreScheduling from './components/theatre-scheduling/TheatreScheduling.vue'
import SetReferralIn from './components/set-referral-in/SetReferralIn.vue'
import PaymentNotificationVue from './components/payment-notification/PaymentNotification.vue'
import ScheduleForAdmission from './components/schedule-admission/ScheduleForAdmission.vue'

export default {
  name: 'PatientInfo',
  components: {
    PopOverSidebar, 
    PatientDetails,
    Encounter,
    SignOff,
    RequestForAmbulance,
    NextAppointment,
    EndQueueSession,
    CallPatientInQueue, SetReferralIn,
    TransferPatient,
    ScheduleForAdmission



  },
  props: {
    encounter: Object,
    items: Array,
    unSubmittedProcedures: Array,
    medicationOrder: Object,
    serviceUnit: Object,
    fullScreenWorkArea: Boolean,
  },
  data() {
    return {
      infoModal: {
        id: 'info-modal-submit',
        title: '',
        data: {},
        content: {},
      },
      text: '',
      toBill: true,
      validPf: false,
      closeEncounter: false,
      collapsed: false,
      iconUp: 'fa-angle-up',
      iconDown: 'fa-angle-down',
    }
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
  },
  mounted() {
    this.items = [this.patient]
  },

  methods: {
    updateEncounter(encounter) {
      this.$store.dispatch('encounter/setEncounter', encounter)
    },
    deathNotificationDialog() {
      const parent = this
      new frappe.ui.Dialog({
        title: 'Death Notification',
        fields: [
          {
            label: 'Patient',
            fieldname: 'patient',
            fieldtype: 'Link',
            options: 'Patient',
            default: parent.patient.patient,
            reqd: 1,
          },
          { fieldtype: 'Column Break' },
          {
            label: 'Healthcare Practitioner',
            fieldname: 'practitioner_pronouncing_death',
            fieldtype: 'Link',
            options: 'Healthcare Practitioner',
            default: parent.encounter.practitioner,
            reqd: 1,
          },
          { fieldtype: 'Section Break', label: 'Discharge Summary' },
          {
            label: 'Immediate Cause',
            fieldname: 'immediate_cause',
            reqd: 1,
            fieldtype: 'Text',
          },
          {
            label: 'Underlying Cause',
            fieldname: 'underlying_cause',
            reqd: 1,
            fieldtype: 'Text',
          },
        ],
        primary_action_label: 'Submit',
        primary_action(values) {
          console.log(values)
          dlg.hide()
        },
        secondary_action_label: 'Cancel',
        secondary_action() {
          dlg.hide()
        },
      }).show()
    },
    // bill standing charges
    billInpatientStandingCharges() {
      dischargeOrTransferBilling({
        inpatient_record: this.patient.name,
      }).then(data => { })
    },
    scheduleDischarge() {
      // this.show_availability();
      const parent = this
      var dialog = new frappe.ui.Dialog({
        title: 'Discharge Summary',
        fields: [
          {
            fieldtype: 'Date',
            label: 'Discharge Ordered Date',
            fieldname: 'discharge_ordered_date',
            default: 'Today',
            read_only: 1,
          },
          {
            fieldtype: 'Date',
            label: 'Followup Date',
            fieldname: 'followup_date',
          },
          {
            fieldtype: 'Select',
            label: 'Mortality Status',
            fieldname: 'mortality_status',
            reqd: 1,
            options: ['Alive', 'Dead'],
            change: () => {
              const mortality_status = dialog.get_value('mortality_status')
              if (mortality_status === 'Dead') {
                // parent.deathNotificationDialog();
                dialog.hide()
                frappe.new_doc('Death Notification', {
                  patient: parent.patient.patient,
                })
                /* let dlg = */

                // dlg.show();
              } else {
                // nothing
              }
            },
          },
          { fieldtype: 'Column Break' },
          {
            fieldtype: 'Long Text',
            label: 'Discharge Instructions',
            fieldname: 'discharge_instructions',
          },
          { fieldtype: 'Section Break', label: 'Discharge Summary' },
          {
            fieldtype: 'Long Text',
            label: 'Discharge Note',
            fieldname: 'discharge_note',
          },
        ],
        primary_action_label: ('Order Discharge'),
        primary_action() {
          const args = {
            patient: parent.patient.patient,
            discharge_encounter: parent.encounter.name,
            discharge_practitioner: parent.encounter.practitioner,
            discharge_ordered_date: dialog.get_value('discharge_ordered_date'),
            followup_date: dialog.get_value('followup_date'),
            discharge_instructions: dialog.get_value('discharge_instructions'),
            discharge_note: dialog.get_value('discharge_note'),
          }
          scheduleDischarge(args).then(r => {
            this.show_alert(
              {
                message: ('Discharge Scheduled'),
                indicator: 'green',
              },
              5,
            )
          }),
            dialog.hide()
          // bill standing charges
          parent.billInpatientStandingCharges()
        },
      })
      dialog.show()
      dialog.fields_dict.followup_date.df.onchange = () => {
        parent.show_availability(dialog)
      }
    },
    show_availability(dialog) {
      const parent = this
      let selected_practitioner = ''
      const d = new frappe.ui.Dialog({
        title: ('Appointment slots'),
        fields: [
          {
            fieldtype: 'Link',
            options: 'Medical Department',
            reqd: 1,
            fieldname: 'department',
            label: 'Medical Department',
          },
          { fieldtype: 'Column Break' },
          {
            fieldtype: 'Link',
            options: 'Healthcare Practitioner',
            reqd: 1,
            fieldname: 'practitioner',
            label: 'Healthcare Practitioner',
          },
          { fieldtype: 'Column Break' },
          {
            fieldtype: 'Date',
            reqd: 1,
            fieldname: 'appointment_date',
            label: 'Date',
          },
          { fieldtype: 'Section Break' },
          { fieldtype: 'HTML', fieldname: 'available_slots' },
        ],
        primary_action_label: ('Book Follow-Up'),
        primary_action(values) {
          // console.log(JSON.stringify(values))
        },
      })

      d.fields_dict.department.df.onchange = () => {
        d.set_values({
          practitioner: '',
        })
        const department = d.get_value('department')
        if (department) {
          d.fields_dict.practitioner.get_query = function () {
            return {
              filters: {
                department,
              },
            }
          }
        }
      }

      // disable dialog action initially
      d.get_primary_btn().attr('disabled', true)

      // Field Change Handler
      const fd = d.fields_dict
      d.fields_dict.appointment_date.df.onchange = () => {
        show_slots(d, fd)
      }
      d.fields_dict.practitioner.df.onchange = () => {
        if (
          d.get_value('practitioner')
          && d.get_value('practitioner') != selected_practitioner
        ) {
          selected_practitioner = d.get_value('practitioner')
          show_slots(d, fd)
        }
      }
      d.show()
      function show_slots(d, fd) {
        let selected_slot = ''
        let service_unit = ''
        if (d.get_value('appointment_date') && d.get_value('practitioner')) {
          fd.available_slots.html('')
          getAvailabilityData({
            practitioner: d.get_value('practitioner'),
            date: d.get_value('appointment_date'),
          }).then(r => {
            console.log(JSON.stringify(r))
            const data = r
            if (data.slot_details.length > 0) {
              const { $wrapper } = d.fields_dict.available_slots

              // make buttons for each slot
              const { slot_details } = data
              let slot_html = ''
              let duration = ''
              for (let i = 0; i < slot_details.length; i++) {
                slot_html += `<label>${slot_details[i].slot_name}</label>`
                slot_html = `${slot_html
                  }<br/>${slot_details[i].avail_slot
                    .map(slot => {
                      let disabled = ''
                      const start_str = slot.from_time
                      const slot_start_time = moment(slot.from_time, 'HH:mm:ss')
                      const slot_to_time = moment(slot.to_time, 'HH:mm:ss')
                      const interval = ((slot_to_time - slot_start_time) / 60000) | 0
                      // iterate in all booked appointments, update the start time and duration
                      slot_details[i].appointments.forEach(booked => {
                        const booked_moment = moment(
                          booked.appointment_time,
                          'HH:mm:ss',
                        )
                        const end_time = booked_moment
                          .clone()
                          .add(booked.duration, 'minutes')
                        // Deal with 0 duration appointments
                        if (
                          booked_moment.isSame(slot_start_time)
                          || booked_moment.isBetween(slot_start_time, slot_to_time)
                        ) {
                          if (booked.duration == 0) {
                            disabled = 'disabled="disabled"'
                            return false
                          }
                        }
                        // Check for overlaps considering appointment duration
                        if (
                          slot_start_time.isBefore(end_time)
                          && slot_to_time.isAfter(booked_moment)
                        ) {
                          // There is an overlap
                          disabled = 'disabled="disabled"'
                          return false
                        }
                      })
                      if (disabled) {
                        return `<button class="btn btn-light"
                      data-name=${start_str}
                      data-duration=${interval}
                      data-service-unit="${slot_details[i].service_unit || ''}"
                      style="margin: 0 10px 10px 0; width: 72px;" ${disabled}>
                      ${start_str.substring(0, start_str.length - 3)}
                    </button>`
                      }
                      return `<button class="btn btn-primary"
                      data-name=${start_str}
                      data-duration=${interval}
                      data-service-unit="${slot_details[i].service_unit || ''}"
                      style="margin: 0 10px 10px 0; width: 72px;" ${disabled}>
                      ${start_str.substring(0, start_str.length - 3)}
                    </button>`
                    })
                    .join('')}`

                slot_html += '<br/>'
              }

              $wrapper
                .css('margin-bottom', 0)
                .addClass('text-center')
                .html(slot_html)

              // blue button when clicked
              $wrapper.on('click', 'button', function () {
                const $btn = $(this)
                $wrapper.find('button').removeClass('btn-primary')
                $btn.addClass('btn-primary')
                selected_slot = $btn.attr('data-name')
                service_unit = $btn.attr('data-service-unit')
                duration = $btn.attr('data-duration')
                // enable dialog action
                d.get_primary_btn().attr('disabled', false)
                createAppointment({
                  patient: parent.patient.patient,
                  service_unit,
                  practitioner: d.get_value('practitioner'),
                  department: d.get_value('department'),
                  appointment_date: d.get_value('appointment_date'),
                  appointment_time: selected_slot,
                  referring_practitioner: parent.encounter.practitioner,
                }).then(r => {
                  if (r) {
                    d.hide()
                    this.show_alert(
                      {
                        message: ('Follow Up Appointment Created'),
                        indicator: 'green',
                      },
                      5,
                    )
                  }
                })
              })
            } else {
              //	fd.available_slots.html('Please select a valid date.'.bold())
              show_empty_state(
                d.get_value('practitioner'),
                d.get_value('appointment_date'),
              )
            }
          })
        } else {
          fd.available_slots.html(
            (
              'Appointment date and Healthcare Practitioner are Mandatory'
            ).bold(),
          )
        }
      }
    },
    // },
    getFullPatientInfo(patientName) {
      getPatientInfo(patientName).then(
        patientData => (this.patient = patientData),
      )
    },
    resetInfoModal() {
      this.infoModal.content = {}
    },
    submitEncounterAction(encounter = this.encounter) {
      this.unSubmittedProcedures.forEach(({ name }) => {
        api({
          method: 'clinical.api.patient_encounter.patient_encounter.submit',
          args: {
            is_emergency: this.patient.is_emergency,
            name,
            doctype: 'Clinical Procedure',
            service_unit: this.serviceUnit,
          },
        }).then(encounter => {
          this.updateEncounter({})
        })
      })
      submitLabPrescriptions({
        service_unit: this.patient.service_unit,
        admission_status: this.patient.status,
        encounter_name: this.encounter.name,
      }).then(data => { })

      if (encounter.name) {
        const toBill = this.serviceUnit.service_unit_details
          .bill_for_every_encounter
          ? this.toBill
          : false
        submitEncounter(encounter.name, this.serviceUnit, toBill).then(
          res => {
            this.$emit('encounterUpdated', res)
            this.resetInfoModal()
          },
        )
        submitDoctorsNotes(encounter.name).then(res => this.resetInfoModal())
      }

      if (this.medicationOrder.name) {
        submitMedicationOrder(this.medicationOrder.name).then(res => {
          this.$emit('medicationOrderUpdated', {})
          this.resetInfoModal()
        })
      }
      this.$emit('sign-off')
    },
    confirmRemovalFromList() {
      frappe.confirm(
        'Are you sure you want to remove this patient list from the list?. Ensure all pending bills have been cleared',
        () => {
          closeAppointment(this.patient.name).then(res => {
            this.updateList()
          })
        },
        () => {
          // action to perform if No is selected
        },
      )
    },
    updateList() {
      const payload = {
        serviceUnit: this.selectedServiceUnit.service_unit,
        search: '',
        work: 'patient/component/pateintinfosm',
      }
      this.$store.dispatch('patient/fetchPatients', payload)
    },
    scheduleAdmission() {
      const me = this
      const dialog = new frappe.ui.Dialog({
        title: 'Patient Admission',
        fields: [
          {
            fieldtype: 'Link',
            label: 'Medical Department',
            fieldname: 'medical_department',
            options: 'Medical Department',
          },
          {
            fieldtype: 'Text Editor',
            label: 'Admission Instruction',
            fieldname: 'admission_instruction',
          },
        ],
        primary_action_label: ('Schedule Admission'),
        primary_action: values => {
          if (!this.encounter.name) {
            createPatientEncounter(this.patient.patient).then(encounter => {
              scheduleAdmissionApi({
                ...values,
                patient: this.patient.patient,
                encounter: encounter.name,
              }).then(() => {
                this.$emit('encounterUpdated', encounter)
                this.submitEncounterAction(encounter)
                closeAppointment(this.patient.name).then(res => {
                  this.updateList()
                })
                dialog.hide()
              })
            })
          } else {
            scheduleAdmissionApi({
              ...values,
              patient: this.patient.patient,
              encounter: this.encounter.name,
            }).then(() => {
              me.submitEncounterAction(this.encounter)
              closeAppointment(this.patient.name).then(res => {
                this.updateList()
              })
              dialog.hide()
            })
          }
        },
      })
      dialog.show()
    },
    admitPatient() {
      const dialog = new frappe.ui.Dialog({
        title: `Admit ${this.patient.patient} to ${this.serviceUnit.service_unit}`,
        fields: [
          {
            fieldtype: 'Link',
            fieldname: 'service_unit',
            options: 'Healthcare Service Unit',
            filters: {
              is_group: 0,
              name: ['like', '%BED%'],
              occupancy_status: 'Vacant',
            }, // [['is_group', '=', 0], ['name', 'like', '%BED%'], ['occupancy_status', '=', 'Vacant']],
            label: 'Bed',
          },
        ],
        primary_action_label: ('Admit'),
        primary_action: values => {
          checkAdmissionEligibility({
            inpatient_record: this.patient.name,
            service_unit: values.service_unit,
          }).then(data => {
            if (data == 1) {
              admitPatient({
                ...values,
                inpatient_record: this.patient.name,
              }).then(() => {
                this.updateList()
                dialog.hide()
              })
            } else {
              this.msgprint({
                title: ('Notification'),
                indicator: 'red',
                message: (
                  'Patient does not have enough funds to cover minimum admission charges for this service_unit'
                ),
              })
            }
          })
        },
      })
      dialog.show()
    },
    openModal(item, button) {
      this.infoModal.title = 'Submit Patient Encounter'
      this.infoModal.content = item
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    blur() {
      validatePFNumber(this.text).then(value => {
        this.validPf = value
        console.log('=========>', value)
      })
    },
    focus() {
      validatePFNumber(this.text).then(value => {
        this.validPf = false
      })
    },
    transferPatient() {
      const dialog = new frappe.ui.Dialog({
        title: 'Transfer Inpatient',
        fields: [
          {
            fieldtype: 'Link',
            fieldname: 'service_unit',
            options: 'Healthcare Service Unit',
            filters: {
              is_group: 0,
              name: ['like', '%BED%'],
              occupancy_status: 'Vacant',
            },
            label: 'Transfer to Bed',
          },
        ],
        primary_action_label: ('Transfer'),
        primary_action: values => {
          const callback = () => dialog.hide()
          const payload = {
            patient: this.patient,
            ...values,
            inpatient_record: this.patient.name,
            leave_from: this.patient.service_unit,
            callback,
          }
          this.$store.dispatch('patient/transferPatient', payload)
          // bill standing charges
          this.billInpatientStandingCharges()
          this.msgprint({
            title: ('Notification'),
            indicator: 'green',
            message: ('Patient successfully transferred'),
          })
        },
      })
      dialog.show()
    },
  },
}
</script>

<style scoped>
.patient-info {
  font-size: 11px;
  padding-right: 5px;
  padding-left: 0px;
  padding-top: 1%;
  padding-bottom: 1%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 0px !important;
}

.info {
  flex: 1;
}

.to-bill {
  margin-top: 8px;
  margin-bottom: 16px;
}

.custom-control {
  padding-left: 4px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}

#collapse-95 {
  margin-bottom: 0px !important;
}

.bgstyle {
  background: #d3d3d34a;
  border-radius: 11px;
  margin-bottom: 4px;
  margin-right: 0px !important;
  margin-left: -3px !important;
}
</style>
