import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const requestAmbulance = payload => api({
  method: 'fleet.api.fleet_request.post_ambulance_request',
  args: {
    payload,
  },
})
