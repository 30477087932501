import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))
export const createPatientAllergy = payload => api({
  method: 'clinical.api.notes.patient_allergy.execute',
  args: { payload },
})

export const patientAllergyAddItem = payload => api({
  method: 'clinical.api.notes.patient_allergy.add_to_patient_allergy',
  args: { payload },
  freeze: false,
})

export const patientAllergyRemoveItem = name => api({
  method: 'clinical.api.notes.patient_allergy.remove_item_from_patient_allergy',
  args: { name },
})

export const checkPatientAllergy = patient => api({
  method: 'clinical.api.notes.patient_allergy.check_if_exits',
  args: { patient },
})

export const patientAllergySubmit = name => api({
  method: 'clinical.api.notes.patient_allergy.submit_patient_allergy',
  args: { name },
  freeze: false,
})

export const patientAllergyDelete = name => api({
  method: 'clinical.api.notes.patient_allergy.delete_patient_allergy',
  args: { name },
  freeze: false,
})
