<template>
  <div>
    <i><a
      target="_blank"
      @click="$bvModal.show('amodal')"
    >My Location: {{ myLocation.service_point_name || "Select a Service Point" }}</a></i>
    <div>
      <b-modal
        v-if="isVisible"
        id="amodal"
        hide-footer
      >
        <template #modal-title>
          Take a break and vacate service unit.
        </template>
        <div>
          <b-button
            v-if="isVisible"
            class="pull-right"
            variant="danger btn-sm"
            @click="vacateServicePoint"
          >
            Vacate
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { vacateServicePoint } from './service'

export default {
  name: 'VacateServicePoint',
  props: {
    details: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: 1,
    }
  },
  computed: {
    patient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    selectedPatientDetails() {
      return this.$store.getters['patient/getSelectedPatientDetails']
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint'] || (this.servicePoints.length && this.servicePoints[0])
    },
    myLocation() {
      return this.$store.getters['servicePoint/getMyLocation']
    },
  },
  created() {
  },
  methods: {
    vacateServicePoint() {
      this.isVisible = 0
      vacateServicePoint(this.selectedServiceUnit.service_unit).then(
        res => {
          this.show_alert({
            message: ('Service point vacated'),
            indicator: 'green',
          }, 5)
          this.isVisible = 1
          this.$store.dispatch('servicePoint/setMyLocation', { service_point: '', service_point_name: '' })
        },
      )
    },
  },

}
</script>

<style></style>
