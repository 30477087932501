import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getUnsubmittedProcedures = (patient, practitioner) => api({
  method: 'clinical.api.orders.prescriptions.get_unsubmitted_procedures',
  args: {
    patient,
    practitioner,
  },
})

export const sendSocketMesage = payload => api({
  method: 'clinical.intergrations.socketio.emit',
  args: { payload },
})
