import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const vacateServicePoint = service_unit => api({
  method: 'clinical.api.queue_management.queue_logistics.vacate_service_point',
  args: {
    service_unit,
  },
})
