import SideBar from './sidebar/Sidebar.vue'
import WorkingArea from './working-area/WorkingArea.vue'
import { getUnsubmittedProcedures, sendSocketMesage } from './service'

import socketModule from '../state/socket/moduleSocket.js'
import notificationModule from '../state/notification/moduleNotification.js'
import encounterModule from '../state/patient-encounter/module.js'
import contextModule from '../state/context/index.js'
import serviceUnitModule from '../state/serviceUnit/moduleServiceUnit.js'
import TopHeader from './top-header/TopHeader.vue'

export default {
  name: 'Main',
  data() {
    return {
      title: 'Patient Chart',
      selectedPatient: {},
      encounter: {},
      medicationOrder: {},
      unSubmittedProcedures: [],
      sideBarNormalSize: [12, 3, 3],
      wordkingAreaNormalSize: [12, 9, 9],
      fullScreenWorkArea: false,
      mobile: false,
      searchResultText: 'Searching...',
      apiList: [],
      socket: null,
      currentNotification: null,
      showNotification: 0,
      userNotifications: [],
      apiRecall: 0,
    }
  },

  methods: {
    checkServiceUnitNotifications() {
      // getServiceUnitNotifications(this.selectedServiceUnit.service_unit).then((res)=>{
      //     if (res.show_notification == 1){
      //         this.showNotification = 1
      //         this.userNotifications = res.notifications
      //     }
      //     else{
      //         this.showNotification = 0
      //         getServiceUnitNotifications(this.selectedServiceUnit.service_unit).then((res)=>{
      //             if (res.show_notification == 1){
      //                 this.showNotification = 1
      //                 this.userNotifications = res.notifications
      //             }
      //         })
      //     }
      // })
    },
    setPatient(patient) {
      this.selectedPatient = patient
      this.setContext({
        key: 'patient_name',
        value: this.selectedPatient.patient,
      })
    },
    setContext(payload) {
      this.$store.dispatch('context/setContextItem', payload)
    },
    getPatientList(serviceUnit, search) {
      console.log('Searching for patient ....')
      if (serviceUnit) {
        const payload = { serviceUnit, search, work: 'patient/component/main2' }
        this.$store.dispatch('patient/fetchPatients', payload)
      }
    },
    clearSearch() {
      // this.getPatientList(this.selectedServiceUnit.service_unit, "");
    },
    search(search) {
      this.getPatientList(this.selectedServiceUnit.service_unit, search)
    },
    updateList() {
      console.log('UPDATE LIST CALLED')
      const payload = {
        serviceUnit: this.selectedServiceUnit.service_unit, servicePoint: this.selectedServicePoint.name, search: '', work: 'patient/component/main',
      }
      this.$store.dispatch('patient/fetchPatients', payload)
    },
    toggleWorkingAreaSpace(value) {
      this.fullScreenWorkArea = value
    },
    maximize() {
      this.sideBarNormalSize = [12, 1, 1]
      this.wordkingAreaNormalSize = [12, 11, 11]
    },
    minimize() {
      this.sideBarNormalSize = [12, 3, 3]
      this.wordkingAreaNormalSize = [12, 9, 9]
    },

    setServiceUnit(unit) {
      this.selectedServiceUnit = unit

      if (this.selectedServiceUnit) {
        this.setContext({
          key: 'service_unit_name',
          value: this.selectedServiceUnit.service_unit,
        })
      }
      console.log('==========>', unit.unit)
      if (unit && unit.unit.service_unit) {
        this.getPatientList(unit.service_unit, '')
      }
      console.log('==========>', unit.unit)
    },
    encounterUpdated(encounter) {
      this.encounter = encounter
      if (encounter.patient && encounter.practitioner) {
        getUnsubmittedProcedures(
          encounter.patient,
          encounter.practitioner,
        ).then(val => {
          this.unSubmittedProcedures = val
        })
      }
    },
    medicationOrderUpdated(order) {
      this.medicationOrder = order
    },
    onSocket(data) {
      // console.log(data);
    },
    get_host(port = 3000) {
      let host = window.location.origin
      if (window.dev_server || true) {
        const parts = host.split(':')
        port = frappe.boot.socketio_port || port.toString() || '3000'
        if (parts.length > 2) {
          host = `${parts[0]}:${parts[1]}`
        }
        host = `${host}:${port}`
      }
      return host
    },
    startListening() { },
    setupListnerForSocket(socket) {
      if (this.selectedServiceUnit.name) {
        socket.on(this.selectedServiceUnit, data => {
          this.sendSocketDataToStore(data)
        })
      }
    },
    testSendData() { },
    sendSocketDataToStore(data) {
      this.$store.dispatch('socket/setSocketData', data)
    },
    checkIfMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.mobile = true
      }
    },
    showAlert(notification) {
      if (this.currentNotification !== notification) {
        this.$bvToast.toast(notification.message, {
          title: notification.title,
          autoHideDelay: 5000,
          appendToast: true,
        })
        this.currentNotification = notification
      }
    },
    initModules() {
      this.$store.registerModule('socket', socketModule)
      this.$store.registerModule('notification', notificationModule)
      this.$store.registerModule('context', contextModule)
      this.$store.registerModule('encounter', encounterModule)
      this.$store.registerModule('serviceUnit', serviceUnitModule)
    },
  },
  watch: {
    fullScreenWorkArea(fullWorkSpace) {
      if (fullWorkSpace) {
        this.maximize()
      } else {
        this.minimize()
      }
    },
    socketData(socketData) {
      if (socketData.messageType === 'subscriptionUpdate') {
        if (socketData.action === 'off') {
          this.socket.off(socketData.event)
        } else if (socketData.action === 'on') {
          this.socket.on(socketData.event, data => {
            this.sendSocketDataToStore(data)
          })
        }
      } else if (socketData.messageType === 'emit') {
        sendSocketMesage(socketData)
      }
    },
    selectedServiceUnit(current, old) {
      this.checkServiceUnitNotifications()
      if (current) {
        this.setContext({ key: 'service_unit_name', value: current.name })
      }

      if (old.service_unit) {
        this.socket.off(old)
      }

      if (current.service_unit) {
        this.socket.on(current.service_unit, data => {
          if (data.messageType === 'notification') {
            if (data.data.message !== 'Patient turned up') {
              this.$store.dispatch('notification/addNotification', data.data)
            }
            // this.showAlert(data.data);
            if (data.data.title === 'Patient Chart Notifications') {
              // console.log("WATCH SERVICE UNIT")
              // this.updateList()
              this.checkServiceUnitNotifications()
            }
          }
        })
      }
    },

  },

  mounted() {
    this.setTitle("Timeline")
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    const host = this.get_host(3000)
    const { user } = this.getUserSession()
    const { socket } = frappe.socketio
    this.socket = socket
    socket.on(user, data => {
      if (data.data.messageType === 'notification') {
        // this.showAlert(data.data);
        const payload = { ref: user }
        this.$store.dispatch('notification/fetchNotifications', payload)
      }
    })

    this.checkIfMobile()
    this.setupListnerForSocket(socket)
  },
  created() {
    this.initModules()
    this.checkIfMobile()
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint']
    },
    socketData() {
      return this.$store.getters['socket/getSocketData']
    },
  },
  components: {
    SideBar,
    WorkingArea,
    TopHeader,
  },
}
