<template>
  <b-container>
    <b-button
      v-if="selectedServicePoint.point_name"
      variant="primary"
      size="sm"
      @click="$bvModal.show('s_p_m')"
    >
      <i
        class="fa fa-home"
        aria-hidden="true"
      />  <i><a
        style="color:white"
        target="_blank"
        @click="$bvModal.show('s_p_m')"
      >{{ selectedServicePoint.point_name }}</a></i>

    </b-button>
    <div>
      <b-modal
        v-if="isVisible"
        id="s_p_m"
        hide-footer
      >
        <template #modal-title>
          Service point manager
        </template>
        <div>
          <b-col
            cols="12"
            class="no-background"
          >
            <b-dropdown
              :text="selectedServicePoint.point_name || 'Select a service point'"
              variant="secondary"
              split
              split-variant="outline-secondary"
              class="fill-width custom-menu-bg"
            >
              <b-dropdown-item
                v-for="point in servicePoints"
                :key="point.name"
                @click="setServicePoint(point)"
              >{{ point.point_name }}</b-dropdown-item>
            </b-dropdown>
          </b-col>

          <b-col
            cols="12"
            class="no-background"
            style="margin-top:10px"
          >
            <b-button
              class="pull-right"
              variant="primary btn-sm"
              @click="seePatientsHere"
            >
              See Patients Here
            </b-button>
          </b-col>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>
<script>

import { updatePractitionerOccupancy } from './service'

export default {
  name: 'ServicePointSelect',
  components: {},
  data() {
    return {
      isVisible: 1,
    }
  },
  computed: {
    selectedServicePoint() {
      return this.$store.getters['servicePoint/getSelectedServicePoint'] || (this.servicePoints.length && this.servicePoints[0])
    },
    myLocation() {
      return this.$store.getters['servicePoint/getMyLocation']
    },
    servicePoints() {
      const service_points = this.$store.getters['servicePoint/getServicePoints']

      if (this.myLocation.service_point !== null) {
        service_points.forEach((item, index) => {
          if ((item.name).trim() === (this.myLocation.service_point).trim()) {
            service_points.splice(index, 1)
            service_points.unshift(item)
          }
        })
      }

      return service_points
    },
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit'] || (this.serviceUnits.length && this.serviceUnits[0])
    },

  },
  watch: {
    servicePoints(service_points) {
      this.$store.dispatch('servicePoint/setSelectedServicePoint', service_points[0])
    },
  },
  methods: {
    seePatientsHere() {
      this.isVisible = 0
      updatePractitionerOccupancy(this.selectedServiceUnit.service_unit, this.selectedServicePoint.name).then(
        res => {
          this.show_alert({
            message: ('Service point location set'),
            indicator: 'green',
          }, 5)
          this.isVisible = 1
        },
      )

      this.$store.dispatch('servicePoint/setMyLocation', { service_point: this.selectedServicePoint.name, service_point_name: this.selectedServicePoint.point_name })
    },
    setServicePoint(point) {
      this.$store.dispatch('servicePoint/setSelectedServicePoint', point)
    },
  },
}
</script>
<style>
.fill-width {
  width: 100% !important;
}
.firt-text {
  font-size: large;
}
.side-bar-area {
  background: green;
}
.cust-nav {
  box-shadow: 0px 0px !important;
  padding: 0px !important;
}

.ward-selection {
  background: rgba(76, 175, 80, 0);
  padding: auto;
}

.unit-title {
  font-weight: 700;
  color: dimgray;
  margin-bottom: 0px !important;
}

.no-background {
  background: rgba(76, 175, 80, 0);
  margin-top:2px;
}
.custom-menu-bg {
  background: white;
  border-radius: 7px;
  border: 0px;
}
</style>
