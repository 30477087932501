import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const updatePractitionerOccupancy = (service_unit, service_point) => api({
  method: 'clinical.api.queue_management.queue_logistics.update_practitioner_occupancy',
  args: {
    service_unit,
    service_point,
  },
})
