import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data.message))
  .catch(error => reject(error)))

export const getPatientMedicationOrders = patient => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.fetch_medication_orders',
  freeze: false,
  args: {
    entry: { patient },
  },
})

export const createMedicationEntry = ({ order, ward }) => api({
  method: 'clinical.api.inpatient_drug_administration.medication_entry.create_medication_entry',
  args: {
    order,
    ward,
  },
})

export const validatePFNumber = number => api({
  method: 'clinical.api.inpatient_drug_administration.medication_entry.validate_employee',
  freeze: false,
  args: {
    number: number.trim(),
  },
})

export const ignoreDrugAdministration = args => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.ignore_drug_administration',
  args,
}).then(({ message }) => message)

export const undoDrugAdministration = args => api({
  method: 'clinical.api.inpatient_drug_administration.inpatient_drug_administration.undo_drug_administration',
  args,
}).then(({ message }) => message)
