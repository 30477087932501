<template>
  <b-container class="area-background">
    <PopOverSideBar></PopOverSideBar>
    <!-- <marquee scrollamount="15" style="height:50px;margin-top:5px;margin-bottom:-15px;"></marquee> -->
    <b-row
      v-if="!selectedPatient.patient || selectedPatient.status == 'Awaiting Visit Payment'"
      class="text-center"
    >
      <b-col
        id="patient-inf"
        class="patient-inf"
      >
        <b-card
          style="width: 100%;height:78vh;margin-top:15px;"
          no-body
          class="text-center"
        >

          <b-container style="margin-top:50px;">
            <b-row
              id="over"
              style="width:100%"
            >
              <b-col cols="2" />
              <b-col>
                <img
                  style="height: calc(100vh -200); width: 100%"
                  src="https://portal.mtrh.go.ke/assets/frontend/img/6301-min.png"
                  alt="No Data"
                >

              </b-col>
              <b-col cols="2" />

            </b-row>
            <b-row>
              <h3
                class="mx-auto"
                @click="testSocket()"
              >
                No Patient has been selected
              </h3>
            </b-row>
           
            <b-row><span class="mx-auto">Individual patient's data will be shown here</span></b-row>
            <b-row class="w-100" align-h="center">
              <b-button class="mt-1" size="sm" v-b-toggle.sidebar-1678  variant="dark"> Open Patient Queue</b-button>
            </b-row>
          </b-container>
        </b-card>
      </b-col>

    </b-row>

    <b-row v-else>
      <b-col
        v-if="showNotification == 1"
        id="patient-info"
        cols="12"
        class="patient-info"
      >
        <b-card
          v-if="selectedPatient && selectedPatient.name"
          style="width: 100%"
          no-body
          class="top-menu cardx"
        >
          <!-- <chart-notifications @notificationRead="notificationRead" :userNotifications="userNotifications"/> -->
        </b-card>
      </b-col>

      <b-col
        id="patient-info"
        cols="12"
        class="w-100"
      >
        <div
          v-if="selectedPatient && selectedPatient.name"
          no-body
          class="w-100"
          style="padding-left: 10px;"
        >
          <component
            :is="patientInfoType"
            :service-unit="selectedServiceUnit"
            :un-submitted-procedures="unSubmittedProcedures"
            :medication-order="medicationOrder"
            :encounter="encounter"
            :full-screen-work-area="fullScreenWorkArea"
            :patient="selectedPatient"
            @medicationOrderUpdated="medicationOrderUpdated"
            @encounterUpdated="encounterUpdated"
            @updateList="updateList"
          />
        </div>
      </b-col>

      <b-col
        v-if="selectedServiceUnit.service_unit_details.medical_department != selectedPatient.medical_department && selectedServiceUnit.service_unit_details.allow_appointments == 0"
        cols="12"
      >

        <b-alert
          variant="danger"
          show
        ><i
          class="fa fa-exclamation-triangle"
          aria-hidden="true"
        /> This patient is
          not admitted to currently selected service unit. As a result inpatient activities have been
          disabled</b-alert>
        <b-alert
          variant="success"
          show
        ><i
          class="fa fa-lightbulb-o"
          aria-hidden="true"
        /> Search for the patient
          in the rightful service unit or Transfer this patient to the currently selected service unit</b-alert>

      </b-col>
      <b-col
        v-if="selectedServiceUnit.service_unit_details.allow_appointments == 0 && selectedPatient && selectedPatient.name && selectedServiceUnit.service_unit_details.medical_department == selectedPatient.medical_department"
        cols="12"
        class=""
      >
        <div
          no-body
          class="top-menu cardx mobile-margin"
        >
          <patient-chart
            :full-screen-work-area="fullScreenWorkArea"
            :service-unit="selectedServiceUnit"
            :selected-patient="selectedPatient"
            :service-units="serviceUnits"
            :last-action="lastAction"
            :encounter="encounter"
            :medication-order="medicationOrder"
            @tab-actions="setActions"
            @tab-buttons="setButtons"
            @encounterUpdated="encounterUpdated"
            @medicationOrderUpdated="medicationOrderUpdated"
          />
        </div>
      </b-col>

      

      <b-col
        v-if="selectedServiceUnit.service_unit_details.allow_appointments == 1 && selectedPatient && selectedPatient.name"
        cols="12"
        class=""
      >
        <div
          no-body
          class="top-menu cardx mobile-margin"
        >
          <patient-chart
            :full-screen-work-area="fullScreenWorkArea"
            :service-unit="selectedServiceUnit"
            :selected-patient="selectedPatient"
            :service-units="serviceUnits"
            :last-action="lastAction"
            :encounter="encounter"
            :medication-order="medicationOrder"
            @tab-actions="setActions"
            @tab-buttons="setButtons"
            @encounterUpdated="encounterUpdated"
            @medicationOrderUpdated="medicationOrderUpdated"
          />
        </div>
      </b-col>
   
    </b-row>
  </b-container>
</template>

<script>
import PatientInfo from './components/patient-info/PatientInfo.vue'
import PatientInfoSm from './components/patient-info/PatientInfoSm.vue'
import PatientChart from './components/patient-chart/PatientChart.vue'
import PopOverSideBar from '../sidebar/PopOverSidebar.vue'
import {
  getMyServiceUnits,
  getDraftPatientEncounters,
  getDraftMedicationOrder,
} from './service'
import TopHeader from '../top-header/TopHeader.vue'

export default {
  name: 'WorkingArea',
  components: {
    PatientChart,
    PatientInfo,
    PatientInfoSm,
    TopHeader,
    PopOverSideBar,
    // ChartNotifications
  },
  props: {
    selectedServiceUnit: Object,
    encounter: Object,
    unSubmittedProcedures: Object,
    medicationOrder: Object,
    fullScreenWorkArea: Boolean,
    showNotification: Number,
    userNotifications: Array,
  },
  data() {
    return {
      lastAction: '',
      currentTabActions: [],
      currentTabButtons: [],
      serviceUnits: [],
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      notificationMessage: '',
      mobile: false,
      patientInfoType: 'PatientInfo',
    }
  },
  computed: {
    selectedServiceUnit() {
      return this.$store.getters['serviceUnit/getSelectedServiceUnit']
    },
    socketData() {
      return this.$store.getters['socket/getSocketData']
    },
    selectedPatient() {
      return this.$store.getters['patient/getSelectedPatient']
    },
    patients() {
      return this.$store.getters['patient/getPatients']
    },
  },

  watch: {
    selectedPatient(val) {
      if (val && val.patient) {
        getDraftPatientEncounters(this.selectedPatient.patient).then(
          encounter => {
            this.$emit('encounterUpdated', encounter)
          },
        )

        getDraftMedicationOrder(this.selectedPatient.patient).then(order => {
          this.$emit('medicationOrderUpdated', order)
        })
      }
    },
    socketData(data) {
      if (data.action === 'notification') {
        this.notificationMessage = `Patient ${data.data.title} updated`
        this.showAlert(data.data)
        console.log(data)
      } else {
        this.showAlert(data.data)
      }
    },
  },
  created() {
    this.$store.dispatch('servicePoint/fetchMyLocation')
    this.checkIfMobile()
    if (this.mobile) {
      this.patientInfoType = 'PatientInfoSm'
    }
    this.getServiceUnits({})
  },
  methods: {
    notificationRead() {
      this.$emit('notificationRead')
    },
    toggleFullView() {
      this.fullScreenWorkArea = !this.fullScreenWorkArea
      this.$emit('full-screen-work-area', this.fullScreenWorkArea)
    },
    updateList() {
      const payload = {
        serviceUnit: this.selectedServiceUnit.service_unit,
        search: '',
        work: 'patient/core/workingarea',
      }
      this.$store.dispatch('patient/fetchPatients', payload)
    },
    setServiceUnit(unit) {
      this.selectedServiceUnit = unit
      this.$emit('selected-service-unit', unit)
    },
    actionClicked(actionName) {
      this.lastAction = actionName
    },
    setActions(actions) {
      this.currentTabActions = actions
    },
    setButtons(buttons) {
      this.currentTabButtons = buttons
    },
    getServiceUnits(user) {
      getMyServiceUnits({}).then(result => (this.serviceUnits = result))
    },
    encounterUpdated(encounter) {
      this.$emit('encounterUpdated', encounter)
    },
    medicationOrderUpdated(order) {
      this.$emit('medicationOrderUpdated', order)
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(notification) {
      // this.dismissCountDown = this.dismissSecs;
      const msg = `${notification.title} ${notification.message}`
      this.$toast.open({
        message: msg,
        type: 'info',
        queue: true,
        position: 'bottom-right',

        // all of other options may go here
      })
    },
    checkIfMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        this.mobile = true
      }
    },
  },
}
</script>

<style scoped>
.info-bay {
    animation: blink 4s linear infinite;
}

.top-menu {
    padding: 0px !important;
}

.section-margin-space {
    margin-top: 3%;
}

.cardx {
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%) !important;
    transition: 0.3s;
    border-radius: 7px !important;
}

.area-background {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.highest-card {
    z-index: 1;
}

.notification {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .patient-info {
        margin-right: 20px;
        margin-left: 8px;
    }

    .mobile-margin {
        margin-right: 0px !important;
    }
}

@keyframes blink {
    0% {
        opacity: 5;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 5;
    }
}</style>
